<template>
  <div>
    {{ reservedBy }}
  </div>
</template>
<script>
import { isNotUndefinedAndNotNull } from "@/utils/classes";

export default {
    props: {
      item: {
        type: Object,
        default: () => {},
      }
    },
    computed: {
      reservedBy() {
        let fullName = '';
        const user = this.item.userClient ? 'userClient' :  'userClub';

        if (!isNotUndefinedAndNotNull(this.item[user])) return '-';
        if (isNotUndefinedAndNotNull(this.item[user].firstName)) fullName = this.item[user].firstName;
        if (isNotUndefinedAndNotNull(this.item[user].lastName)) fullName += ` ${this.item[user].lastName}`;

        return fullName;
      },
    }
  }
</script>
